import {
  getAccountPostingsTransactionsSearchResults,
  getHighlightsResult,
  getComponent,
  getMinimalComponentsRecordsSearchResults,
  getStatementsSearchResults,
} from "@shared/api";
import { keyFactory } from "@shared/api/hooks/utils";
import {
  AccountPostingsTransactionsSearchPayload,
  AccountPostingsTransactionsSearchResults,
  ComponentPayload,
  MinimalComponentsRecordsSearchPayload,
  MinimalComponentsRecordsSearchResults,
  StatementsSearchPayload,
  StatementsSearchResults,
} from "@shared/constants/mortgages-servicing";
import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
} from "@tanstack/react-query";

export const minimalComponentsKeys = keyFactory("minimal-components");

export const paginatedMinimalComponentsKeys = keyFactory(
  "paginated-minimal-components"
);

export const highlightsResultKeys = keyFactory("highlights-result");

export const componentKeys = keyFactory("component");

export const statementsKeys = keyFactory("statements");

export const accountPostingsTransactionsKeys = keyFactory(
  "account-postings-transactions"
);

/**
 * This hook gets the minimal components records search results
 * @param payload Payload used as query params
 * @param options Query options (select, enabled, keepPreviousData)
 * @returns The minimal components records search results
 */
export const useGetMinimalComponentsRecordsSearchResults = <
  TResult = MinimalComponentsRecordsSearchResults,
>(
  payload?: MinimalComponentsRecordsSearchPayload,
  options?: Pick<
    UseQueryOptions<MinimalComponentsRecordsSearchResults, unknown, TResult>,
    "select" | "enabled" | "keepPreviousData"
  >
) =>
  useQuery(
    minimalComponentsKeys.list({ payload }),
    () => getMinimalComponentsRecordsSearchResults(payload),
    options
  );

/**
 * This hook gets the paginated minimal components records search results
 * @param payload Payload used as query params
 * @param options Query options (select, enabled, keepPreviousData)
 * @returns The paginated minimal components records search results
 */
export const useGetPaginatedMinimalComponentsRecordsSearchResults = <
  TResult = MinimalComponentsRecordsSearchResults,
>(
  payload: MinimalComponentsRecordsSearchPayload,
  options = {} as UseInfiniteQueryOptions<
    MinimalComponentsRecordsSearchResults,
    unknown,
    TResult
  >
) =>
  useInfiniteQuery({
    queryKey: paginatedMinimalComponentsKeys.list({ payload }),
    queryFn: ({ pageParam = 1 }) =>
      getMinimalComponentsRecordsSearchResults({
        ...payload,
        page: pageParam,
      }),
    getNextPageParam: (allPages, pages) => {
      const totalPages = allPages.totalPages;

      if (pages.length >= totalPages) return undefined;

      return pages.length + 1;
    },
    ...options,
  });

/**
 * This hook gets the highlights result
 * @returns The highlights result
 */
export const useGetHighlightsResult = () =>
  useQuery(highlightsResultKeys.all, () => getHighlightsResult());

/**
 * This hook gets the account postings transactions search results
 * @param payload Payload used as query params
 * @param options Infinite query options
 * @returns The account postings transactions search results
 */
export const useGetAccountPostingsTransactionsSearchResults = <
  TResult = AccountPostingsTransactionsSearchResults,
>(
  payload: AccountPostingsTransactionsSearchPayload,
  options = {} as UseInfiniteQueryOptions<
    AccountPostingsTransactionsSearchResults,
    unknown,
    TResult
  >
) =>
  useInfiniteQuery({
    queryKey: accountPostingsTransactionsKeys.list({ payload }),
    queryFn: ({ pageParam = 1 }) =>
      getAccountPostingsTransactionsSearchResults({
        ...payload,
        page: pageParam,
      }),
    getNextPageParam: (allPages, pages) => {
      const totalPages = allPages.totalPages;

      if (pages.length >= totalPages) return undefined;

      return pages.length + 1;
    },
    ...options,
  });

/**
 * This hook gets the statements search results
 * @param payload Payload used as query params
 * @param options Infinite query options
 * @returns The statements search results
 */
export const useGetStatementsSearchResults = <
  TResult = StatementsSearchResults,
>(
  payload: StatementsSearchPayload,
  options = {} as UseInfiniteQueryOptions<
    StatementsSearchResults,
    unknown,
    TResult
  >
) =>
  useInfiniteQuery({
    queryKey: statementsKeys.list({ payload }),
    queryFn: ({ pageParam = 1 }) =>
      getStatementsSearchResults({
        ...payload,
        page: pageParam,
      }),
    getNextPageParam: (allPages, pages) => {
      const totalPages = allPages.totalPages;

      if (pages.length >= totalPages) return undefined;

      return pages.length + 1;
    },
    ...options,
  });

/**
 * This hook gets a single component
 * @param payload Payload used as path params
 * @returns A single component
 */
export const useGetComponent = (payload: ComponentPayload) =>
  useQuery(componentKeys.detail({ payload }), () => getComponent(payload));
